import React, { useState, useEffect, useCallback, createContext } from 'react';

const LayoutContext = createContext({});

export const LayoutProvider = props => {
	const [ scrollDirection, setScrollDirection ] = useState('');
	const [ scrollOn, setScrollOn ] = useState('top');

	const [ activeFunction, setActiveFunction ] = useState({ resize: 'resize' });

	const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
	const handleOnScroll = useCallback(doc => {
		// Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

		let sd = window.pageYOffset || document.documentElement.scrollTop;
		let lastScrollTop = 0;
		if (sd > lastScrollTop) {
			// downscroll code
			setScrollDirection('down');
		} else {
			// upscroll code
			setScrollDirection('up');
		}
		const st = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

		const PREV_LOADING_HIEGHT = 200 * window.devicePixelRatio;

		if (!st) {
			setScrollOn('top');
		} else if (st + document.documentElement.clientHeight + PREV_LOADING_HIEGHT >= document.documentElement.scrollHeight) {
			setScrollOn('bottom');
		} else {
			setScrollOn(null);
		}
		lastScrollTop = st <= 0 ? 0 : st;
	}, []);

	const handleOnSize = useCallback(() => {
		setWindowWidth(window.innerWidth);
	}, []);
	useEffect(
		() => {
			let supportsPassive = false;
			if (activeFunction['resize']) {
				window.addEventListener('resize', handleOnSize, supportsPassive ? { passive: true } : false);
			}
			if (activeFunction['scroll']) {
				window.addEventListener('scroll', handleOnScroll);
			}

			return () => {
				if (activeFunction['resize']) {
					window.removeEventListener('resize', handleOnSize);
				}
				if (activeFunction['scroll']) {
					window.removeEventListener('scroll', handleOnScroll);
				}
			};
		},
		[ activeFunction ]
	);

	return <LayoutContext.Provider value={{ scrollDirection, scrollOn, windowWidth, setActiveFunction }}>{props.children}</LayoutContext.Provider>;
};
export const withLayout = Component => props => {
	return (
		<LayoutProvider>
			<Component {...props} />
		</LayoutProvider>
	);
};

export default LayoutContext;
