const getLanguage = () => {
	let language = localStorage.getItem('language');
	if (!language) {
		language = window.navigator.userLanguage || window.navigator.language;
	}
};

export default getLanguage;
const getCondition = (actionType, error = {}) => {
	if (actionType === 'start') {
		return { isLoading: true, error: {}, isSuccess: false };
	} else if (actionType === 'fail') {
		return { isLoading: false, error, isSuccess: false };
	} else {
		return { isLoading: false, error: {}, isSuccess: true };
	}
};
export const dispatchAction = (dispatch, promise, actionType, errorTitle = 'Error') => async data => {
	try {
		dispatch({ actionType: 'start', condition: getCondition('start') });
		const payload = await promise(data);
		dispatch({ actionType, payload, condition: getCondition(actionType) });
		console.log(payload)
		return payload;
	} catch (err) {
		console.log(err)
		if (err.response) {
			dispatch({ actionType: 'fail', condition: getCondition('fail', { ...err.response.data, title: errorTitle }) });
			throw err.response.data;
		} else {
			dispatch({ actionType: 'fail', condition: getCondition('fail', { ...err, title: errorTitle }) });
			throw err;
		}
	}
};
export const dispatchLocalAction = (dispatch, actionType) => async payload => {
	try{
		dispatch({actionType,payload, condition: getCondition()});
		return payload;
	}catch{
		
	}
};
export const setItemAction = (setState, promise, method, initState = {}) => async data => {
	const payload = await promise(data);
	setState(value => {
		switch (method) {
			case 'update':
				return { ...value, ...payload };
			case 'clear':
				return initState;
			default:
				return payload;
		}
	});
};

export const initListState = {
	isLoading: false,
	isSuccess: null,
	errorMessage: null,
	list: []
};
export const initItemState = {
	isLoading: false,
	isSuccess: null,
	errorMessage: null,
	item: {}
};

export const initBasicState = {
	name: null,
	condition: { isLoading: false, isSuccess: null, error: {} }
};
export const initRestfulState = {
	name: null,
	condition: { isLoading: false, isSuccess: null, error: {} },
	id: null,
	items: [],
	item: {}
};
