import React, { createContext, useReducer, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import getLanguage from '../../API/lib/getLanguage';
import GlobalContext from '../../contexts/Global/GlobalContext';
import localStorageSafe from 'zach-local-storage-safe';
import { authRoute, postRoute, manageRoute } from '../../map/map';
import { dispatchAction,dispatchLocalAction } from '../lib/index';
import parseQuery from '../../lib/parseQuery';
import {
	checkavailabilityAPI,
	signUpPhoneAPI,
	loginWechatAPI,
	loginAPI,
	userinfoUpdateAPI,
	getProfileAPI,
	userinfoPasswordChangeAPI,
	putProfileAPI,
	putProfileImageAPI,
	userinfoPasswordResetRequestByphoneAPI,
	sendCodeAPI,
	userinfoPasswordResetAPI,
	profilelatLngAddressLocationAPI,
	factoryMember
} from '../../API/auth.api';

const API = process.env.REACT_APP_AUTH_API;
const ROUTE_NAME = '/auth';
// export const authAxios = axios.create({ baseURL: API + ROUTE_NAME, headers: { 'Cache-Control': 'no-cache' } });
export const authAxios = axios.create({ baseURL: API + ROUTE_NAME });

const Context = createContext({});

if (process.env.NODE_ENV === 'development') {
	factoryMember();
}

export const localAuthFields = [ 'accessToken', 'refreshToken', 'thirdToken', 'roles' ];
const initAuthState = {
	auth: {},
	user: {},
	accessToken: null,
	refreshToken: null,
	...localStorageSafe.getItem('authState', 'object'),
    condition:{isLoading:false,error:{},isSuccess:false}
};
const authReducer = (state = {}, action = {}) => {
	const { actionType, condition = {} } = action;
	const { error={} } = condition;
	const payload = action.payload || {};
	switch (actionType) {
		case 'start':
			return {
				...state,
				...action
			};
		case 'fail':
			if (error.title) {
				window.pushError({ title: error.title, message: getLanguage() === 'zh' ? error.cnErrorMessage : error.enErrorMessage });
			}
			return {
				...state,
				...action
			};
		case 'checkAuth':
			return { ...state, actionType, ...payload };
		case 'getAuth':
		case 'getLogin':
		case 'getUser':
			localStorageSafe.setItem('authState', { ...state, actionType, ...payload });
			return { ...state, ...action, ...payload };
		case 'updatedPW':
			localStorageSafe.setItem('authState', { ...state, actionType, auth: { ...state.auth, passwordSet: true } });
			return { ...state, ...action, auth: { ...state.auth, passwordSet: true } };
		case 'getCode':
			return { ...state, ...action, code: payload.code };
		case 'clearAuth':
			localStorageSafe.setItem('prevAuthState',{...localStorageSafe.getItem('authState', 'object')});
			localStorage.removeItem('authState');
			localStorage.removeItem('member');
			return {    
                auth:{},
                user:{},
                accessToken:null,
                refreshToken:null,
                condition:{isLoading:false,error:{},isSuccess:false}
            };
		default:
			return { ...state };
	}
};
const resultHandler = ({actionType,payload}) => {
    switch (actionType){
		case 'checkAccount':
			console.log(payload.auth)
			if (payload.auth.userRegistered === true) {
                if(payload.auth.passwordSet === true){
                    window.pushG(`${authRoute.loginPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
                }else{
                    window.pushG(`${authRoute.loginPhoneCode.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
				}			
			} else if (payload.auth.userRegistered === false) {
				window.pushG(`${authRoute.signUpPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
			}
			break;
		case 'getProfile':
		case 'checkAuthState':
			// if (payload.auth && payload.auth.passwordSet === false) {
			// 	window.pushG(authRoute.signFirstPassword.url);
			// }else if (!payload.user.fileUrl || !payload.user.displayName) {
			// 	window.pushG(authRoute.setProfile.url);
			// }else if(!payload.user.latLng){
			// 	window.pushG(authRoute.setLocation.url);
			// }
			if(!payload.user.latLng){
				window.pushG(authRoute.setLocation.url);
			}
			break;
		case 'updateProfile':
		case 'updateProfileImage':
			// if (!payload.user.fileUrl || !payload.user.displayName) {
			// 	window.pushG(authRoute.setProfile.url);
			// }else if(!payload.user.latLng){
			// 	window.pushG(authRoute.setLocation.url);
			// }
			payload.user && window.alertG('Update Success',{icon:'ui icon check'})
			break;
		// case 'signFirstPassword':
		// 	if (payload.updatedPassword){
		// 		window.pushG(authRoute.setProfile.url);
		// 	} else {
		// 		window.alertG('Update Fail',{icon:'ui icon close'})
		// 	}
		// 	break;
		case 'fastLogin':
		case 'signUpPhone':
		case 'loginPhone':
		case 'loginWechat':
		case 'resetPasswordByCode':
		case 'updateProfileAddressLocation':
			// if (payload.auth && payload.auth.passwordSet === false) {
			// 	window.pushG(authRoute.signFirstPassword.url);
			// }else if (!payload.user.fileUrl || !payload.user.displayName) {
			// 	window.pushG(authRoute.setProfile.url);
			// }else if(!payload.user.latLng){
			// 	window.pushG(authRoute.setLocation.url);
			// }else{
			// 	window.pushG(postRoute.main.to);
			// }
			if(!payload.user.latLng){
				window.pushG(authRoute.setLocation.url);
			}else{
				window.pushG(postRoute.main.to);
			}
			break;
		case 'updatePassword':
            payload.passwordChanged && window.alertG('Update Success',{icon:'ui icon check'})
			break;
		case 'signFirstPassword':
			payload.updatedPassword && window.alertG('Update Success',{icon:'ui icon check'})
			break;
		case 'resetPasswordByPhoneVerificationCode':
			window.pushG(authRoute.resetPassword.url + '?' + parseQuery.parseObjToQueryStr({ code:payload.code, countryCode:payload.countryCode, phoneNumber:payload.phoneNumber }))
			break;
        default:
            break;
    }
}
export const Provider = props => {
	const [ state, dispatch ] = useReducer(authReducer, initAuthState);
	const { accessToken } = state;
	const { push } = useContext(GlobalContext);
	const checkAccountAction = useCallback(dispatchAction(dispatch, checkavailabilityAPI, 'checkAuth'), [ dispatch ]);
	const signUpPhoneAction = useCallback(dispatchAction(dispatch, signUpPhoneAPI, 'getAuth'), [ dispatch ]);
	const loginWechatAction = useCallback(dispatchAction(dispatch, loginWechatAPI, 'getAuth'), [ dispatch ]);
	const loginAction = useCallback(dispatchAction(dispatch, loginAPI, 'getLogin','Login fail'), [ dispatch ]);
	const signFirstPasswordAction = useCallback(dispatchAction(dispatch, userinfoUpdateAPI, 'updatedPW'), [ dispatch ]);
	const getProfileAction = useCallback(dispatchAction(dispatch, getProfileAPI, 'getUser'), [ dispatch ]);
	const updateProfileAction = useCallback(dispatchAction(dispatch, putProfileAPI, 'getUser'), [ dispatch ]);
	const updateProfileImageAction = useCallback(dispatchAction(dispatch, putProfileImageAPI, 'getUser','Error','Update Success', [ dispatch ]));
	const updatePasswordAction = useCallback(dispatchAction(dispatch, userinfoPasswordChangeAPI, 'success'), [ dispatch ]);
	const sendPhoneVerifyCodeAction = useCallback(dispatchAction(dispatch, sendCodeAPI, 'success'), [ dispatch ]);
	const resetPasswordByPhoneVerificationCodeAction = useCallback(dispatchAction(dispatch, userinfoPasswordResetRequestByphoneAPI, 'getCode'), [ dispatch ]);
	const resetPasswordByCodeAction = useCallback(dispatchAction(dispatch, userinfoPasswordResetAPI, 'getAuth'), [ dispatch ]);
	const updateProfileAddressLocationAction = useCallback(dispatchAction(dispatch, profilelatLngAddressLocationAPI, 'getUser'), [ dispatch ]);
	const logoutAction = useCallback(dispatchLocalAction(dispatch,'clearAuth'),[]);
	useEffect(() => {
		if (!localStorage.getItem('loginDate') || (new Date(localStorage.getItem('loginDate')) < new Date('2020-03-20'))) {
			
			//localStorage.clear();  xinwang edit 20200106
		}
	}, []);

	const _onChange = useCallback((e, data = {}) => {
		const { actionType, files, code, latLng, address, verificationCode, countryCode, phoneNumber, password, user = {}, oldPassword, newPassword } = data;

		switch (actionType) {
			case 'checkAccount':
				checkAccountAction({ countryCode, phoneNumber }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'signUpPhone':
				signUpPhoneAction({ countryCode, phoneNumber, verificationCode }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'signFirstPassword':
				signFirstPasswordAction({ password }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'loginPhone':
				loginAction({ countryCode, phoneNumber, password,verificationCode }).then(payload=>resultHandler({actionType,payload}))
				break;
			case 'getProfile':
				getProfileAction().then(payload=>resultHandler({actionType,payload}));
				break;
			case 'updateProfile':
				updateProfileAction({ ...user }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'updateProfileImage':
				updateProfileImageAction({ files }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'updatePassword':
				updatePasswordAction({ oldPassword, newPassword }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'sendPhoneVerifyCode':
				sendPhoneVerifyCodeAction({ countryCode, phoneNumber }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'resetPasswordByPhoneVerificationCode':
				resetPasswordByPhoneVerificationCodeAction({ countryCode, phoneNumber, verificationCode }).then(payload=>resultHandler({actionType,payload:{code:payload.code, countryCode, phoneNumber}})
				);
				break;
			case 'resetPasswordByCode':
				resetPasswordByCodeAction({ code, password: newPassword }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'fastLogin':
				dispatchLocalAction(dispatch,'getLogin')({...data.payload}).then(payload=>resultHandler({actionType,payload}))
				break;
			case 'updateProfileAddressLocation':
				updateProfileAddressLocationAction({ latLng, address }).then(payload=>resultHandler({actionType,payload}));
				break;
			case 'logout':
				logoutAction().then(window.pushG(authRoute.main.to));
				break;
			default:
				console.log(data);
				break;
		}
	}, []);
	const _onLoad = useCallback((data = {}) => {
		const { loginWechat } = authRoute;
		const { actionType, code } = data;
		window.scrollTo(0, 0);
		if (code) {
			switch (actionType) {
				case loginWechat.url:
					loginWechatAction(data);
					break;
				case 'checkAuthState':
					const checkAuthState = localStorageSafe.getItem('authState', 'object');
					if (checkAuthState.accessToken) {
						dispatch({ actionType: 'getAuth', payload: { ...checkAuthState } });
					}
					break;
				default:
					break;
			}
		}
	}, []);

	return <Context.Provider value={{ state, accessToken, onChange: _onChange, onLoad: _onLoad }}>{props.children}</Context.Provider>;
};

export const withAuth = Componet => props => {
	return (
		<Provider>
			<Componet {...props} />
		</Provider>
	);
};

export default Context;
