/*
 * @Author: your name
 * @Date: 2020-12-16 14:26:45
 * @LastEditTime: 2021-02-22 17:15:09
 * @LastEditors: xinwang edit
 * @Description: In User Settings Edit
 * @FilePath: \roroxiandeweb1.0\client\src\API\mapping\memberToUser.js
 */
import moment from 'moment';
import { genderEnum } from '../enum/member.enum';

const memeberToUser = ({
	gender,
	birthday,
	regionNumber,
	nickName,
	image,
	id,
	fullName,
	serialId,
	phone,
	email,
	contactEmail,
	contactPhone,
	contactRegionNumber,
	userType = 'member',
	language = 'zh',
	description,
	rating,
	organizedCount,
	absentCount,
	attendedCount,
	address,
	latLng,
	createdAt,
	updatedAt,
	...rest
}) => {
	const _image = image || {};
	console.log(image)
	return {
		gender: genderEnum(gender),
		birthDay: birthday ? moment(birthday).format('YYYY/MM/DD') : undefined,
		countryCode: regionNumber,
		displayName: nickName,
		fileUrl: _image.fileUrl,
		id,
		fullName,
		serialId,
		phone,
		email,
		contactEmail,
		contactPhone,
		contactCountry:contactRegionNumber,
		language,
		userType,
		description,
		rating,
		age: birthday ? moment().diff(birthday, 'years', false) : undefined,
		organizedCount,
		absentCount,
		attendedCount,
		address,
		latLng,
		createdAt,
		updatedAt,
		...rest
	};
};

export default memeberToUser;
