import moment from 'moment';
import { genderEnum } from '../enum/member.enum';

const userToMember = ({ gender, birthDay, countryCode,contactCountryCode, displayName, ...rest }) => {
	return {
		birthday: birthDay ? new moment(birthDay).valueOf() : undefined,
		gender: genderEnum(gender),
		regionNumber: countryCode,
		nickName: displayName,
		contactRegionNumber: contactCountryCode,
		...rest
	};
};

export default userToMember;
