import React, { useContext, useState, useEffect, useCallback, useReducer } from 'react';
import _ from 'lodash';
import LanguageContext, { withLanguage } from './LanguageContext';
import LayoutContext, { withLayout } from './LayoutContext';
// import localStorageSafe from '../../lib/localStorageSafe';
import { withRouter } from 'react-router-dom';
import { getMember } from '../../API/member.api';
import { mapboxGeoRevserseCityAPI } from '../../API/mapbox.api';

// import moment from 'moment';
// import axios from 'axios';
// import useGPS from '../../hooks/useGPS';
const GlobalContext = React.createContext({});

// const setLoalSearchHistory = (setValue, max = 10) => text => {
// 	let searchHistory = { text, updatedAt: moment() };
// 	let _searchHistoryItems = localStorageSafe.getItem('searchHistoryItems', 'array');
// 	let _searchHistoryObj = _searchHistoryItems.reduce((historyObj, history) => {
// 		historyObj[history.text] = history;
// 		return historyObj;
// 	}, {});
// 	if (!_searchHistoryObj[text]) {
// 		searchHistory.times = 1;
// 	} else {
// 		searchHistory.times = _searchHistoryObj[text].times + 1;
// 	}
// 	_searchHistoryItems = _.unionBy([ searchHistory, ..._searchHistoryItems ], 'text').slice(0, max);
// 	localStorageSafe.setItem('searchHistoryItems', _searchHistoryItems);
// 	setValue(_searchHistoryItems);
// };

const getCity = setState => (coordinates = []) => {
	mapboxGeoRevserseCityAPI(coordinates).then(data => setState(data));
};

const Provider = props => {
	const { history: { push, replace, goBack } } = props;
	const languageContext = useContext(LanguageContext);
	const layoutContext = useContext(LayoutContext);
	const [ members, setMembers ] = useState([]);
	const [ error, setError ] = useState({});
	const [ region, setRegion ] = useState(null);
	const [ modalImageUrl, setModalImageUrl ] = useState(null);
	// const [ searchHistoryItems, setSearchHistoryItems ] = useState(localStorageSafe.getItem('searchHistoryItems', 'array'));
	const [ state, setState ] = useState({ ...languageContext, ...layoutContext });
	// const [ agent ] = useState(_.includes(navigator.userAgent, 'Mobile') ? 'Mobile' : 'Desktop');
	// const [ agentSystemType ] = useState(
	// 	/windows/i.test(navigator.userAgent || navigator.vendor || window.opera)
	// 		? 'Windows'
	// 		: /android/i.test(navigator.userAgent || navigator.vendor || window.opera) ? 'Android' : /iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera) && !window.MSStream ? 'IOS' : 'Unknown'
	// );
	const [ alertState, setAlertObj ] = useState({});
	const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
	const pushError = useCallback(data => {
		setError(data);
	}, []);
	const alertG = useCallback((message, data) => {
		console.log(message,data)
		setAlertObj({ message, ...data });
	}, []);

	const getCityAction = useCallback(getCity(setRegion), []);

	useEffect(() => {
		window.alertG = alertG;
		window.pushG = push;
		window.replaceG = replace;
		window.goBackG = goBack;
		window.pushError = pushError;
	}, []);

	useEffect(
		() => {
			setState(state => ({ ...state, ...languageContext }));
		},
		[ languageContext ]
	);
	// useEffect(
	// 	() => {
	// 		setState(state => ({ ...state, ...layoutContext }));
	// 	},
	// 	[ layoutContext ]
	// );
	const handleOnSize = useCallback(() => {
		setWindowWidth(window.innerWidth);
	}, []);
	const _onClickImage = useCallback(e => {
		setModalImageUrl(e.target.src);
	}, []);
	const _onChange = useCallback((e, data = {}) => {
		switch (data.actionType) {
			case 'setImageUrl':
				setModalImageUrl(data.imageUrl);
				break;
			case 'getCity':
				console.log(data);
				getCityAction(data.coordinates);
				break;
			default:
				break;
		}
	}, []);

	useEffect(() => {
		let supportsPassive = false;
		window.addEventListener('resize', handleOnSize, supportsPassive ? { passive: true } : false);
		return () => {
			window.removeEventListener('resize', handleOnSize);
		};
	}, []);
	const updateMembers = useCallback(
		memeberId => {
			if (members.find(({ id }) => id === memeberId) && memeberId) {
				console.log(members.find(({ id }) => id === memeberId));
			} else {
				if (memeberId) {
					getMember({ id: memeberId }).then(data => {
						setMembers(members => _.uniqBy([ ...members, data ], ({ id }) => id));
					});
				}
			}
		},
		[ members ]
	);
	return (
		<GlobalContext.Provider
			value={{
				...state,
				error,
				pushError,
				modalImageUrl,
				onClickImage: _onClickImage,
				onChange: _onChange,
				windowWidth,
				alertG,
				alertState,
				setAlertObj,
				updateMembers,
				members,
				region,
				push
			}}>
			{props.children}
		</GlobalContext.Provider>
	);
};

export const GlobalProvider = withRouter(withLayout(withLanguage(Provider)));

export const withGlobal = Component => props => {
	return (
		<Provider>
			<Component {...props} />
		</Provider>
	);
};
export const defaultHeaderConfig = {
	title: '',
	imageUrl: '',
	description: '',
	author: '',
	type: '',
	url: ''
};
export default GlobalContext;
