import axios from 'axios';
import getLanguage from './lib/getLanguage';
import localStorageSafe from 'zach-local-storage-safe';
import { isEmpty, head } from 'lodash';
const mapboxGeocodingPlacesAPI = axios.create({ baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places/' });
const access_token = process.env.REACT_APP_MAPBOX_API;
export const mapboxGeoRevserseCityAPI = async coordinates => {
	let _coordinates = localStorageSafe.getItem('gps', 'object').coordinates;
	if (!isEmpty(coordinates)) {
		_coordinates = coordinates;
	}
	return await mapboxGeocodingPlacesAPI.get(`${_coordinates.join(',')}.json`, { params: { access_token, types: 'region', limit: 1, language: getLanguage() } }).then(res => head(res.data.features).text);
};

// const mapboxGeoRevserseAPI = query =>
// 	axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${process.env.REACT_APP_MAPBOX_API}&` + getLatLngStr()).then(res => res.data).catch(err => {
// 		return { features: [] };
//     });
