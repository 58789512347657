import axios from 'axios';
import localStorageSafe from 'zach-local-storage-safe';
import MockAdapter from 'axios-mock-adapter';
import faker from 'faker';
import moment from 'moment';
import _ from 'lodash';
import memeberToUser from './mapping/memberToUser';
import userToMember from './mapping/userToMember';
import autharizedHeaders from './lib/autharizedHeaders';
import factoryMemberSource from './factory/member.factory';
export const factoryMember = factoryMemberSource;
const userAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/user/member' });
// const authAPI = axios.create({ baseURL: process.env.REACT_APP_AUTH_API + '/auth', headers: { 'Cache-Control': 'no-cache' } });
const authAPI = axios.create({ baseURL: process.env.REACT_APP_AUTH_API + '/auth', headers: { Authorization: 'Basic bG9uZ3Rlcm1fY2xpZW50OmFjbWVzZWNyZXQ=' } });

const fileImagesAPI = axios.create({ baseURL: process.env.REACT_APP_UPLOAD_DATA_API + '/fileUpload/uploadMultipleImages' });

// const mock = new MockAdapter(userAPI, { delayResponse: 2000 });
// mock.onGet('/myMemeber').reply((config = {}) => {
// 	let member = localStorageSafe.getItem('member', 'object');
// 	if (!member.id) {
// 		member = initfakeMember;
// 	}
// 	return [ 200, { member } ];
// });
// mock.onPut('/user/memeber/myMember').reply((config = {}) => {
// 	const { data = {} } = config;
// 	let configData = {};
// 	let member = localStorageSafe.getItem('member', 'object');
// 	if (!member.id) {
// 		member = initfakeMember;
// 	}
// 	try {
// 		configData = JSON.parse(data);
// 	} catch (err) {
// 		configData = {};
// 	}
// 	console.log(configData);
// 	localStorageSafe.setItem('member', { ...member, ...configData.member });
// 	return [ 200, { member: { ...member, ...configData.member } } ];
// });
// // mock.onPost('/sendcode').reply(200, { secondsToExpire: 600 });

export const getProfileAPI = async () => await userAPI.get('/myMember', { headers: autharizedHeaders() }).then(res => ({ user: memeberToUser(res.data.member) }));
export const putProfileAPI = async data =>
	await userAPI.put('/myMember', { ...userToMember(data) }, { headers: autharizedHeaders() }).then(res => {
		console.log(res);
		return { user: memeberToUser(res.data.member) };
	});
export const putProfileImageAPI = async ({ files }) => {
	const uploadedImage = await updateImagesAPI({ files }).then(({ images = [] }) => images[0]);
	return await putProfileAPI({ image: uploadedImage });
};
export const profilelatLngAddressLocationAPI = async ({ latLng, address }) => await putProfileAPI({ latLng, address });
export const updateImagesAPI = async ({ files = [], tagsList = [] }) => {
	const formData = new FormData();
	files.forEach((file, index) => {
		let tags = tagsList[index] || '#default';
		formData.append('images', file);
		formData.append('tagsList', tags);
	});

	return await fileImagesAPI.post('', formData).then(res => res.data);
};

// export const updateProfileAvatar
export const checkavailabilityAPI = async ({ countryCode, phoneNumber }) =>
	await authAPI.get('/login/isregistered', { params: { countryCode, phoneNumber,service:'xiande' } }).then(async res => {
		if (res.data.userRegistered) {
			return { auth: { countryCode, phoneNumber, userRegistered: true,passwordSet:res.data.userInfo.passwordSet } };
		} else {
			const secondsToExpire = await sendCodeAPI({ countryCode, phoneNumber });
			return { auth: { countryCode, phoneNumber, userRegistered: false, secondsToExpire } };
		}
	});
export const signUpPhoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.post('/signup/phone', { countryCode, phoneNumber, verificationCode, service: 'xiande', role: 'member' }).then(async res => {
		const data = res.data || {};

		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/myMember', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => memeberToUser(res.data.member));
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const sendCodeAPI = async ({ countryCode, phoneNumber }) => await authAPI.post('/sendcode', { countryCode, phoneNumber }).then(res => +res.data.secondsToExpire);

export const loginAPI = async ({ countryCode, phoneNumber, username, password, email,verificationCode }) =>
	await authAPI.post('/login', { countryCode, phoneNumber, username, password, email,verificationCode, service: 'xiande', role: 'member' }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/myMember', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => memeberToUser(res.data.member));
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const loginWechatAPI = async ({ code }) =>
	await authAPI.post('/login/wechat', { code, service: 'xiande', role: 'member' }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/myMember', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => memeberToUser(res.data.member));
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const userinfoPasswordResetRequestByphoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.post('/userinfo/password/reset/request/byphone', { countryCode, phoneNumber, verificationCode,service:'xiande' }).then(res => {
		return { countryCode, phoneNumber, verificationCode,...res.data };
	});
export const userinfoBindWechatUrlAPI = async ({ code }) => await authAPI.get('/userinfo/bind/wechat', { code }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then(res => res.data.redirectUrl);
export const userinfoBindWechatAPI = async ({ code }) => await authAPI.put('/userinfo/bind/wechat', { code }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then(res => res.data);
export const userinfoBindPhoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.put('/userinfo/bind/phone', { countryCode, phoneNumber, verificationCode }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then(res => res.data);
export const userinfoPasswordChangeAPI = async ({ oldPassword, newPassword }) => await authAPI.put('/userinfo/password/change', { oldPassword, newPassword }, { headers: autharizedHeaders() }).then(res => res.data);
export const userinfoUpdateAPI = async ({ email, password, phoneNumber, countryCode, redirectDomian }) =>
	await authAPI.put('/userinfo/update', { email, password, phoneNumber, countryCode, redirectDomian }, { headers: autharizedHeaders() }).then(res => res.data);
export const userinfoPasswordResetAPI = async ({ code, password }) =>
	await authAPI.post('/userinfo/password/reset', { code, password, service: 'xiande' }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/myMember', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => memeberToUser(res.data.member));
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

//Registered action
export const controllerFieldObj = {
	postLogin: { name: 'postLogin', type: 'getAuth', url: '/login', method: 'post' },
	postLoginPhone: { name: 'postLoginPhone', type: 'getAuth', url: '/login', method: 'post' },
	postSignupEmail: { name: 'postSignupEmail', type: 'getAuth', url: '/signup/email', method: 'post' },
	postSignupPhone: { name: 'postSignupPhone', type: 'getAuth', url: '/signup/phone', method: 'post' },
	postSignupCheck: { name: 'postSignupCheck', type: 'postSignupCheck', url: '/signup/checkavailability', method: 'post' },
	getLoginWechatAuthorize: { name: 'getLoginWechatAuthorize', type: 'getLoginWechatAuthorize', url: '/login/wechat/authorize', method: 'get' },
	postLoginWechat: { name: 'postLoginWechat', type: 'getAuth', url: '/login/wechat', method: 'post' },
	getLoginWechat: { name: 'getLoginWechat', type: 'getLoginWechat', url: '/login/wechat', method: 'get' },
	postSendcode: { name: 'postSendcode', type: 'success', url: '/sendcode', method: 'post' },
	getUserinfo: { name: 'getUserinfo', type: 'getUserinfo', url: '/userinfo', method: 'get' },
	putUserinfoPasswordChange: { name: 'putUserinfoPasswordChange', type: 'putUserinfo', url: '/userinfo/password/change', method: 'put' },
	putUserinfoUpdate: { name: 'putUserinfoUpdate', type: 'putUserinfo', url: '/userinfo/update', method: 'put' },
	postUserinfoPasswordResetRequest: { name: 'postUserinfoPasswordResetRequest', type: 'success', url: '/userinfo/password/reset/request', method: 'post' },
	postUserinfoPasswordReset: { name: 'postUserinfoPasswordReset', type: 'authWebhook', url: '/userinfo/password/reset', method: 'post' },
	postUserinfoEmailVerifyRequest: { name: 'postUserinfoEmailVerifyRequest', type: 'postUserinfoEmailVerifyRequest', url: '/userinfo/email/verify/request', method: 'post' },
	postUserinfoEmailVerify: { name: 'postUserinfoEmailVerify', type: 'authWebhook', url: '/userinfo/email/verify', method: 'post' },
	postUserinfoEmailFind: { name: 'postUserinfoEmailFind', type: 'success', url: '/userinfo/email/find', mehod: 'post' },
	putUserinfoBindWechat: { name: 'putUserinfoBindWechat', type: 'authWebhook', url: '/userinfo/bind/wechat', method: 'put' },
	putUserinfoBindPhone: { name: 'putUserinfoBindPhone', type: 'authWebhook', url: '/userinfo/bind/phone', method: 'put' }
};
