import _ from 'lodash';
import faker from 'faker';
import moment from 'moment';
import localStorageSafe from 'zach-local-storage-safe';
import uuid from 'uuid';
export const fakeMember = ({ id }) => {
	const gender = _.sample([ 0, 1, 2 ]);
	const language = _.sample([ 'zh', 'en' ]);
	const fisrtName = faker.name.firstName(gender);
	const lastName = faker.name.lastName(gender);
	const nickName = _.capitalize(faker.lorem.words(5));
	const pastYear = _.sample(_.times(5, n => n + 20));
	const fakeLocation = _.sample([
		{ address: '3211 Grant McConachie Way, Richmond, BC V7B 0A4', latLng: { lat: 49.1966913, lng: -123.183701 } },
		{ address: '801 W Georgia St, Vancouver, BC V6C 1P7', latLng: { lat: 49.2785028, lng: -123.120169 } },
		{ address: '1829 Quebec St, Vancouver, BC V5T 2Z3', latLng: { lat: 49.268647, lng: -123.1114439 } },
		{ address: '1796 Nanaimo St, Vancouver, BC V5M 1C5', latLng: { lat: 49.2648084, lng: -123.0795865 } },
		{ address: '4393 St George St, Vancouver, BC V5V 4A3', latLng: { lat: 49.2481972, lng: -123.0894307 } }
	]);
	return {
		fullName: fisrtName + ' ' + lastName,
		serialId: faker.random.uuid(),
		phone: faker.phone.phoneNumber('778########'),
		contactEmail: faker.internet.email(fisrtName, lastName, 'testEmail.com'),
		regionNumber: '1',
		language,
		userType: 2,
		image: { fileUrl: 'https://i.imgur.com/rYMDuEm.png', thumbnailUrl: 'https://i.imgur.com/rYMDuEm.png', tags: '#avatarfake' },
		id,
		nickName,
		gender,
		birthday: new moment(faker.date.past(pastYear)).valueOf(),
		description: faker.lorem.paragraphs(gender + 1),
		rating: 100,
		organizedCount: 0,
		absentCount: 0,
		attendedCount: 0,
		address: fakeLocation.address,
		latLng: fakeLocation.latLng,
		createdAt: new Date().getTime(),
		updatedAt: new Date().getTime()
	};
};
const factoryMember = () => {
	if (_.isEmpty(localStorageSafe.getItem('members', 'array'))) {
		const members = _.times(30, n => fakeMember({ id: 1000 + n }));
		localStorageSafe.setItem('members', members);
	}
};
export const initfakeMember = {
	fullName: 'Mock mock',
	serialId: uuid.v4(),
	phone: '1-1111111111',
	contactEmail: 'test@gmail.com',
	regionNumber: '1',
	language: 'zh',
	userType: 1,
	image: { fileUrl: 'https://i.imgur.com/rYMDuEm.png', thumbnailUrl: 'https://i.imgur.com/rYMDuEm.png', tags: '#test' },
	id: 1,
	nickName: 'Mock',
	gender: 2,
	birthday: new moment('1990-01-01').unix(),
	description: 'Hope always after darkness',
	rating: 100,
	organizedCount: 0,
	absentCount: 0,
	attendedCount: 0,
	address: '997 Seymour StVancouver, BC V6B 3M1',
	latLng: { lat: -123.1241788, lng: 49.2789453 },
	createdAt: new Date().getTime(),
	updatedAt: new Date().getTime()
};
export const fakeInitBlockMember = {
	memberBlock: {
		createdAt: 1571786020944,
		updatedAt: 1571786020944,
		memberBlockCompositeKey: {},
		blocker: {
			createdAt: 1571334087682,
			updatedAt: 1571334087700,
			id: 498,
			fullName: null,
			serialId: '06370af9c3424f3bafc65e16883fc17f',
			phone: null,
			regionNumber: null,
			contactEmail: 'hucheng9456@icloud.com',
			language: null,
			image: null,
			nickName: null,
			gender: null,
			birthday: null,
			description: null,
			rating: null,
			organizedCount: null,
			absentCount: null,
			attendedCount: null,
			address: null,
			latLng: null
		},
		blocked: {
			createdAt: 1571334087682,
			updatedAt: 1571334087700,
			id: 498,
			fullName: null,
			serialId: '06370af9c3424f3bafc65e16883fc17f',
			phone: null,
			regionNumber: null,
			contactEmail: 'hucheng9456@icloud.com',
			language: null,
			image: null,
			nickName: null,
			gender: null,
			birthday: null,
			description: null,
			rating: null,
			organizedCount: null,
			absentCount: null,
			attendedCount: null,
			address: null,
			latLng: null
		}
	}
};
export default factoryMember;
