import React, { useMemo, useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authRoute } from '../map/map';
import parseQuery from '../lib/parseQuery';
import localStorageSafe from 'zach-local-storage-safe';
import AuthContext from '../contexts/Global/AuthContext';
import { isEmpty } from 'lodash';

const autharizedHeaders = () => {
	if (localStorage.getItem('testToken')) {
		return { Authorization: `Bearer ` + localStorage.getItem('testToken') };
	} else if (localStorageSafe.getItem('authState', 'object').accessToken) {
		return { Authorization: `Bearer ` + localStorageSafe.getItem('authState', 'object').accessToken };
	} else {
		return {};
	}
};

const RouteAuthorization = ({ component: Component, props: cProps, path, title, name, location, computedMatch, ...rest }) => {
	const { onChange, state: { accessToken } } = useContext(AuthContext);

	useEffect(
		() => {
			if (accessToken) {
				onChange(null, { actionType: 'getProfile' });
			}
		},
		[ accessToken ]
	);
	const render = useMemo(
		() => {
			if (!accessToken) {
				return <Redirect to={authRoute.main.url} />;
			} else {
				return (
					<Route
						path={path}
						location={{ ...location, title, name, query: parseQuery.parse(location.search), parseQuery }}
						computedMatch={computedMatch}
						name={name}
						title={title}
						{...rest}
						render={props => {
							return <Component {...props} {...cProps} />;
						}}
					/>
				);
			}
		},
		[ location, authRoute, title, name ]
	);
	return render;
};

export default RouteAuthorization;
