export const genderList = [ 'male', 'female', 'secret' ];
export const genderEnum = value => {
	switch (value) {
		case 0:
			return genderList[0];
		case 1:
			return genderList[1];
		case 2:
			return genderList[2];
		case genderList[0]:
			return 0;
		case genderList[1]:
			return 1;
		case genderList[2]:
			return 2;
		default:
			return undefined;
	}
};
