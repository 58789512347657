import React, { useMemo,useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authRoute } from '../map/map';
import parseQuery from '../lib/parseQuery';

const RouteBasic = ({ component: Component, props: cProps, path, title, name, location, computedMatch, ...rest }) => {
	const render = useMemo(
		() => {
			if (location.pathname !== authRoute.updateUserinfo.url && localStorage.getItem('thirdToken')) {
				return <Redirect to={authRoute.updateUserinfo.url} />;
			} else {
				return (
					<Route
						path={path}
						location={{ ...location, title, name, query: parseQuery.parse(location.search), parseQuery }}
						computedMatch={computedMatch}
						name={name}
						title={title}
						{...rest}
						render={props => {
							return <Component {...props} {...cProps} />;
						}}
					/>
				);
			}
		},
		[ location, authRoute, title, name ]
	);
	return render;
};

export default RouteBasic;
