import React, { useState, useEffect, createContext } from 'react';
import i18next from 'i18next';
import enLocaleJson from '../../static/locales/en.locale.json';
import zhLocaleJson from '../../static/locales/zh.locale.json';
import moment from 'moment';
import 'moment/locale/zh-cn';

const LanguageContext = createContext({});

let initLanguage = /zh/i.test(navigator.language) ? 'zh' : 'en';
if (localStorage.getItem('language')) {
	initLanguage = localStorage.getItem('language');
}

const options = {
	lng: initLanguage,
	debug: false,
	keySeparator: '>',
	nsSeparator: '|',
	fallbackLng: [ 'en', 'zh' ],
	resources: {
		en: enLocaleJson,
		zh: zhLocaleJson
	}
};
const initT = text => text;
export const LanguageProvider = props => {
	const [ t, setT ] = useState(() => initT);
	const [ currentLanguage, setCurrentLanguage ] = useState(initLanguage);
	useEffect(() => {
		i18next.init(options).then(t => setT(() => t));
		localStorage.setItem('language', initLanguage);
	}, []);
	const setLanguage = language => {
		localStorage.setItem('language', language);
		moment.locale(language==='zh'?'zh-cn':'en');
		setCurrentLanguage(language);
		i18next.changeLanguage(language).then(t => setT(() => t));
	};
	return <LanguageContext.Provider value={{ t, setLanguage, language: currentLanguage, languageList: [ 'en', 'zh' ] }}>{props.children}</LanguageContext.Provider>;
};

export const withLanguage = Component => props => {
	return (
		<LanguageProvider>
			<Component {...props} />
		</LanguageProvider>
	);
};

export default LanguageContext;
