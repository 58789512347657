import React, { createContext, useReducer, useCallback, useState, useEffect } from 'react';
import { getMember,toggleBlock,getBlock } from '../../API/member.api';
import { dispatchAction,dispatchLocalAction } from '../lib';
import _ from 'lodash';
import getLanguage from '../../API/lib/getLanguage';

const Context = createContext({});


const reducer = (state, action) => {
	const { actionType, error = {} } = action;
	const payload = action.payload || {};
	console.log(action);
	switch (actionType) {
		case 'getItems':
		case 'getItem':
		case 'getBlock':
			return { ...state, ... payload };		
		case 'start':
			return { ...state };
		case 'fail':
			if (error.title) {
				window.pushError({ title: error.title, message: getLanguage() === 'zh' ? error.cnErrorMessage : error.enErrorMessage });
			}
			return { ...state, error };
		default:
			console.log(actionType, 'not set in reducer');
			return { ...state };
	}
};

export const Provider = props => {
	const initItemsState = { items:[], condition:{ error:{} }, params:{} };
	const initItemState = { item:{},condition:{ error:{} }, params:{}, block:{} };
	const [ itemsState, dispatchItems ] = useReducer(reducer, initItemsState);
	// const [ members, setMembers ] = useState([]);
	// const updateMembers = useCallback(
	// 	member => {
	// 		const {memberId} = member;
	// 		if(memberId){
	// 			const currentIndex = _.findIndex(members,({ id }) => id === memberId)
	// 			if ( currentIndex > -1) {
	// 				console.log(currentIndex);
	// 			} else {
	// 				getMember({ id: memberId }).then(data => {
	// 					setMembers(members => _.uniqBy([ ...members, data ], ({ id }) => id));
	// 				});
	// 			}
	// 		}
	// 	},
	// 	[ members ]
	// );
	const [ itemState, dispatchItem ] = useReducer(reducer, initItemState);
	const getItemAction = useCallback(dispatchAction(dispatchItem, getMember, 'getItem'), []);
	const toggleBlockAction = useCallback(dispatchAction(dispatchItem,toggleBlock,'getBlock'),[]);
	const getBlockAction = useCallback(dispatchAction(dispatchItem,getBlock,'getBlock',[]));
	const onChange = useCallback((e, data = {}) => {
		const { actionType, id, item = {} } = data;
		switch (actionType) {
			case 'getItem':
				getItemAction({id});
				break;
			case 'toggleBlock':
				toggleBlockAction(item);
				break;
			case 'getBlock':
				getBlockAction({id})
				break;
			default:
				console.log(actionType + ' unset');
				break;
		}
	},[]);
    const onLoad = useCallback(action => {
        const { pathname,query } = action;
        if(/\/member/g.test(pathname)){
            getItemAction({id:query.id})
        }
    },[]);
	useEffect(()=>{
        if(itemState.item && itemState.item.id){
            dispatchLocalAction(dispatchItems,'getItems')({
                items:itemsState.items.map((item)=>{
                    if(item.id!==itemState.item.id){
                        return item
                    }else{
                        return itemState.item
                    }
                })
            })
        }
	},[itemState.item])
	return <Context.Provider value={{ onChange, itemsState, onLoad, itemState }}>{props.children}</Context.Provider>;
};
export const withMember = Componet => props => {
	return (
		<Provider>
			<Componet {...props} />
		</Provider>
	);
};

export default Context;
