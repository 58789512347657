import axios from 'axios';
import localStorageSafe from 'zach-local-storage-safe';
import MockAdapter from 'axios-mock-adapter';
import faker from 'faker';
import moment from 'moment';
import autharizedHeaders from './lib/autharizedHeaders';
import { initfakeMember, fakeMember } from './factory/member.factory';
import memberToUser from './mapping/memberToUser';
import uuid from 'uuid';
import getLocalUser from './lib/getLocalUser';
// import _ from 'lodash';
const conversationAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/conversation' });
const customerFeedback = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/customerFeedback' });
const memberAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/user/member' });
// const mock = new MockAdapter(memberAPI, { delayResponse: 500 });
// mock.onGet(new RegExp('/*')).reply((config = {}) => {
// 	const id = +config.url.replace('https://dev.xiande.roro3.com/xiande-spring-server/api/user/member/', '');

// 	return [ 200, { member: fakeMember({ id }) } ];
// });
export const getMember = async ({ id }) =>
	await memberAPI({ url: '/' + id, method: 'get', headers: autharizedHeaders() }).then(async ({ data }) => {
		// const isBlock = await conversationAPI({ url: '/userBlock/blocked/' + data.member.id, method: 'get', headers: autharizedHeaders() }).then(res => (res.data.memberBlock ? true : false)).catch(err => false);
		return { item:memberToUser(data.member) };
	});

export const sendMemberReport = async ({ description, type = 'report' }) => {
	const { displayName: name, contactEmail, phone, countryCode } = getLocalUser();
	return await customerFeedback({ url: '/', method: 'post', data: { customerFeedback: { uuid: uuid.v1(), description, name, email:contactEmail, phone: '+' + countryCode + phone, type } } });
};

export const toggleBlock = async ({ isBlock, memberId }) => {
	console.log(isBlock,memberId)
	if (!isBlock) {
		return await conversationAPI({ url: '/userBlock/blocked/' + memberId, method: 'put', headers: autharizedHeaders() }).then(() => ({ block:{id:memberId, isBlock: true }}));
	} else {
		return await conversationAPI({ url: '/userBlock/blocked/' + memberId, method: 'delete', headers: autharizedHeaders() }).then(() => ({ block:{id:memberId, isBlock: false }}));
	}
};
export const getBlock = async ({id}) => 
	await conversationAPI({ url: '/userBlock/blocked/' + id, method: 'get', headers: autharizedHeaders() }).then(res => ({ block:{id,isBlock:res.data.memberBlock ? true : false}}))