import localStorageSafe from 'zach-local-storage-safe';
const autharizedHeaders = () => {
	if (localStorage.getItem('testToken')) {
		return { Authorization: `Bearer ` + localStorage.getItem('testToken') };
	} else if (localStorageSafe.getItem('authState', 'object').accessToken) {
		return { Authorization: `Bearer ` + localStorageSafe.getItem('authState', 'object').accessToken };
	} else {
		return {};
	}
};
export default autharizedHeaders;
