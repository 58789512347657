import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { homeRoute, contactRoute, testRoute, policyRoute, downloadRoute, activityRoute, aboutRoute, authRoute, postRoute, conversationRoute, manageRoute, appPolicyRoute, memberRoute, adminRoute } from '../map/map';
import RouteBasic from './RouteBasic';
import RouteAuthorization from './RouteAuthorization';
import InitLoad from '../components/App/InitLoad/InitLoad';
const AppPage = React.lazy(() => import('../components/Pages/AppPage/App'));
const AuthPage = React.lazy(() => import('../components/Pages/AuthPage/AuthPage'));
const PostPage = React.lazy(() => import('../components/Pages/PostPage/PostPage'));
const PromoteHomePage = React.lazy(() => import('../components/Pages/PromoteHomePage/PromoteHomePage'));
// const UserPage = React.lazy(() => import('../components/Pages/UserPage/UserPage'));
const PromoteAboutPage = React.lazy(() => import('../components/Pages/PromoteAboutPage/PromoteAboutPage'));
const PromoteActivityPage = React.lazy(() => import('../components/Pages/PromoteActivityPage/PromoteActivityPage'));
const PromoteContactPage = React.lazy(() => import('../components/Pages/PromoteContactPage/PromoteContactPage'));
const PromotePolicyPage = React.lazy(() => import('../components/Pages/PromotePolicyPage/PromotePolicyPage'));
const PromoteDownloadPage = React.lazy(() => import('../components/Pages/PromoteDownloadPage/PromoteDownloadPage'));
const ConversationPage = React.lazy(() => import('../components/Pages/ConversationPage/ConversationPage'));
const ManagePage = React.lazy(() => import('../components/Pages/ManagePage/ManagePage'));
const AppPolicyPage = React.lazy(() => import('../components/Pages/AppPolicyPage/AppPolicyPage'));
const MemberPage = React.lazy(() => import('../components/Pages/MemberPage/MemberPage'));
const TestPage = React.lazy(() => import('../components/Pages/TestPage/TestPage'));
const AdminPage = React.lazy(() => import('../components/Pages/AdminPage/AdminPage'));

const Routes = props => {
	console.log(props);
	return (
		<Suspense fallback={<InitLoad />}>
			<Switch>
				<RouteBasic exact {...authRoute.main} component={AuthPage} />

				<RouteBasic exact {...testRoute.main} component={TestPage} />

				<RouteAuthorization exact {...manageRoute.main} component={ManagePage} />
				<RouteAuthorization exact {...conversationRoute.main} component={ConversationPage} />
				<RouteAuthorization exact {...postRoute.main} component={PostPage} />
				<RouteAuthorization exact {...appPolicyRoute.main} component={AppPolicyPage} />
				<RouteAuthorization exact {...memberRoute.main} component={MemberPage} />
				{/* <RouteUser {...userRoute.main} component={UserPage} />
				 */}
				{/*==================== Route below should be using RouteUser ===========================*/}
				{/*======================================================================================*/}
				<RouteBasic exact path={homeRoute.main.url} component={PromoteHomePage} />
				<RouteBasic exact path='/' component={PromoteHomePage} />
				<RouteBasic exact {...aboutRoute.main} component={PromoteAboutPage} />
				<RouteBasic exact {...activityRoute.main} component={PromoteActivityPage} />
				<RouteBasic exact {...contactRoute.main} component={PromoteContactPage} />
				<RouteBasic exact {...policyRoute.main} component={PromotePolicyPage} />
				<RouteBasic exact {...downloadRoute.main} component={PromoteDownloadPage} />

				<RouteAuthorization exact {...adminRoute.main} component={AdminPage} />
			</Switch>
		</Suspense>
	);
};
export default Routes;
