export const postRoute = {
	main: {
		url: '/post',
		name: 'post',
		title: 'Post',
		to: '/post',
		// to: '/post?eventType=eat&paymentType=attendee', xinwang edit 20210302
		path: '/post/:target?/:second?'
	},
	detail: {
		url: '/post/detail',
		name: 'detail',
		title: 'Post Detail'
	},
	create: {
		url: '/post/create',
		name: 'create',
		title: 'Post Create'
	},
	mine: {
		url: '/post/mine',
		name: 'mine',
		title: 'Post Mine',
		to: '/post/mine?myEventStatus=attendee'
	},
	search: {
		url: '/post/search',
		name: 'search',
		title: 'Post Search'
	}
};
export const homeRoute = {
	main: {
		url: '/',
		name: 'home',
		title: 'Home',
		to: '/',
		path: '/'
	}
};

export const aboutRoute = {
	main: {
		url: '/about',
		name: 'about',
		title: 'About XianDe',
		to: '/about',
		path: '/about'
	}
};

export const activityRoute = {
	main: {
		url: '/activity',
		name: 'activity',
		title: 'Activity',
		to: '/activity',
		path: '/activity'
	}
};

export const contactRoute = {
	main: {
		url: '/contact',
		name: 'contact',
		title: 'Contact Us',
		to: '/contact',
		path: '/contact'
	}
};

export const policyRoute = {
	main: {
		url: '/policy',
		name: 'policy',
		title: 'Terms Of Use',
		to: '/policy',
		path: '/policy'
	}
};

export const appPolicyRoute = {
	main: {
		url: '/appPolicy',
		name: 'appPolicy',
		title: 'App Policy',
		to: '/appPolicy?title=userTerms',
		path: '/appPolicy'
	},
	disclaimerAgreement: {
		url: '/appPolicy?title=disclaimerAgreement',
		name: 'disclaimerAgreement',
		title: 'Disclaimer Agreement',
		fileName: 'disclaimerAgreement-xiande'
	},
	userTerms: {
		url: '/appPolicy?title=userTerms',
		name: 'userTerms',
		title: 'User Terms',
		fileName: 'customerContract-xiande'
	},
	privacyPolicy: {
		url: '/appPolicy?title=privacyPolicy',
		name: 'privacyPolicy',
		title: 'Privacy Policy',
		fileName: 'privacyPolicy-xiande'
	}
};

export const downloadRoute = {
	main: {
		url: '/download',
		name: 'download',
		title: 'Download',
		to: '/download',
		path: '/download'
	}
};

export const userRoute = {
	main: {
		url: '/user',
		name: 'user',
		title: 'User Center',
		path: '/user/:target?',
		to: '/user'
	},
	profile: {
		url: '/user/profile',
		name: 'profile',
		title: 'View & Edit Profile',
		icon: 'icon-Icon_Manage'
	},
	gallery: {
		url: '/user/gallery',
		name: 'gallery',
		title: 'My Gallery',
		icon: 'icon-Icon_Favorite'
	}
};

export const adminRoute = {
	main: {
		url: '/admin',
		title: 'Admin',
		path: '/admin/:category?',
		name: 'main',
		to: '/admin/client'
	},
	client: {
		url: '/admin/client',
		title: 'Client',
		name: 'client',
		to: '/admin/client'
	}
};

export const authRoute = {
	main: {
		url: '/auth',
		title: 'Auth',
		name: 'main',
		path: '/auth/:category?/:target?/:method?',
		to: '/auth'
	},
	fastLogin:{
		url:'/auth/fastLogin',
		title:'Fast Login',
		name:'fastLogin'
	},
	check: {
		url: '/auth/check',
		title: 'Check Auth',
		name: 'check'
	},
	// login: {
	// 	url: '/auth/login/email',
	// 	title: 'Account Login',
	// 	name: 'login'
	// },
	loginPhone: {
		url: '/auth/login',
		title: 'Account Login Phone',
		name: 'loginPhone'
	},
	loginPhoneCode:{
		url:'/auth/loginPhoneCode',
		title:'Account Login Phone with Code',
		name:'loginPhoneCode'
	},
	// signupEmail: {
	// 	url: '/auth/signup/email',
	// 	title: 'Account Sign Up',
	// 	name: 'signupEmail'
	// },
	signUpPhone: {
		url: '/auth/signUp',
		title: 'Account Sign Up With Phone',
		name: 'signUpPhone'
	},
	signFirstPassword: {
		url: '/auth/signFirstPassword',
		title: 'Sign First Password',
		name: 'signFirstPassword'
	},
	setProfile: {
		url: '/auth/setProfile',
		title: 'Set Profile',
		name: 'setProfile'
	},
	setLocation: {
		url: '/auth/setLocation',
		title: 'Set Location',
		name: 'setLocation'
	},
	updateUserinfo: {
		url: '/auth/update/userinfo',
		title: 'Setup Authorization Infomation',
		name: 'updateUserinfo'
	},
	updatePassword: {
		url: '/auth/update/password',
		title: 'Change Authorization Password',
		name: 'updatePassword'
	},

	resetPassword: {
		url: '/auth/resetPassword',
		title: 'Reset Password',
		name: 'resetPassword',
		icon: 'icon-aaIconChangeCode'
	},
	findPassword: {
		url: '/auth/find/password',
		title: 'Retrieve Password',
		name: 'findPassword',
		subTitle: 'Enter the registered email address to start to retrieve the password'
	},
	findEmail: {
		url: '/auth/find/email',
		title: 'Retrieve Email',
		name: 'findEmail',
		subTitle: 'Enter the registered mobile phone number to start to retrieve the mailbox ID'
	},
	bind: {
		url: '/auth/bind',
		title: 'Account Binding',
		name: 'bind',
		icon: 'icon-aaIconLink'
	},

	loginWechat: {
		url: '/auth/login/wechat',
		title: 'Wechat Login',
		name: 'loginWechat'
	},
	userinfoPWReset: {
		url: '/auth/userinfo/password/reset',
		title: 'Reset Password',
		name: 'userinfoPWReset'
	},
	userinfoEmailVerify: {
		url: '/auth/userinfo/email/verify',
		title: 'Email Verify',
		name: 'userinfoEmailVerify'
	},
	userinfoBindWechat: {
		url: '/auth/userinfo/bind/wechat',
		title: 'Bind Wechat',
		name: 'userinfoBindWechat'
	},
	userinfoBindPhone: {
		url: '/auth/userinfo/bind/phone',
		title: 'Bind Phone',
		name: 'userinfoBindPhone'
	}
};
export const processRoute = {
	main: {
		url: '/process',
		title: 'Process',
		name: 'main'
	}
};
export const conditionRoute = {
	main: {
		url: '/condition',
		title: 'Condition',
		name: 'main'
	}
};
export const manageRoute = {
	main: {
		path: '/manage/:target?',
		url: '/manage',
		name: 'manage',
		title: 'Manage',
		to: '/manage'
	},
	setLocation: {
		url: '/manage/setLocation',
		name: 'setLocation',
		title: 'Set Location',
		to: '/manage/setLocation'
	}
};

export const conversationRoute = {
	main: {
		path: '/conversation/:id?',
		url: '/conversation',
		name: 'conversation',
		title: 'Conversation',
		to: '/conversation'
	},
	detail: {
		url: '/conversation/detail',
		name: 'detail',
		title: 'Conversation Detail'
	}
};

export const memberRoute = {
	main: {
		path: '/member',
		url: '/member',
		name: 'member',
		title: 'Member'
	}
};

export const testRoute = {
	main: {
		path: '/test',
		url: '/test',
		name: 'test',
		title: 'test',
		to: '/test'
	}
};

export const IOS_APP_LINK = 'https://apps.apple.com/app/id1487260123';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.xiandes.android';
export const HUAWEI_APP_LINK = 'https://appgallery.huawei.com/#/app/C103771873';
export const LOCAL_APP_LINK = '../xiandes.apk';