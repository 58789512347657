import React from 'react';
import ReactDOM from 'react-dom';
//import 'semantic-ui-css/semantic.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-day-picker/lib/style.css';
import './hover.css';
import './index.css';
import './fontStyle.css';
import './roroFontStyle.css';
import './animation.css';
import './index.scss';
import faker from 'faker';
import './lib/localStorageSafe';
// import App from './App';
import { GlobalProvider } from './contexts/Global/GlobalContext';
import { Provider as MemberProvider } from './contexts/Global/MemberContext';
import { Provider as AuthProvider } from './contexts/Global/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/routes';
import * as serviceWorker from './serviceWorker';

sessionStorage.setItem('userUUID', faker.random.uuid());

const getGlobalThis = () => {
	// in webworker or service worker
	if (typeof self !== 'undefined') return self; // eslint-disable-line no-restricted-globals

	// in browser
	if (typeof window !== 'undefined') return window;

	// in Node.js
	if (typeof global !== 'undefined') return global;

	// Standalone javascript shell
	if (typeof this !== 'undefined') return this;

	throw new Error('Unable to locate global object');
};

var gThis = getGlobalThis();
console.log(gThis);
var _lsTotal = 0,
	_xLen,
	_x;
for (_x in localStorage) {
	if (!localStorage.hasOwnProperty(_x)) {
		continue;
	}
	_xLen = (localStorage[_x].length + _x.length) * 2;
	_lsTotal += _xLen;
	console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
}
console.log('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');
function hasTouch(){
	return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) {
	// remove all :hover stylesheets
	try {
		// prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match(':hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) {}
}

ReactDOM.render(
	<BrowserRouter>
		<GlobalProvider>
			<AuthProvider>
				<MemberProvider>
					<Routes />
				</MemberProvider>
			</AuthProvider>
		</GlobalProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
